import { ListWithTitleBlock } from '@/blocks/ListWithTitleBlock'
import { TextWithIconBlock } from '@/blocks/TextWithIcon'
import { trackCtaClick } from '@/lib/gtm/gtmV2'
import { StructuredText } from 'react-datocms'
import renderHTML from 'react-render-html'
import styled, { CSSObject } from 'styled-components'
import { TextDescriptionRecord } from 'types'
import { Button } from '../shared/Button'

const StyledTextDescription = styled.div<{ $containerStyle?: CSSObject; $mobileOrder?: number }>`
  align-items: center;
  color: ${(props) => props.theme.v2.onSurface.text};
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    ${(props) => props.$mobileOrder && `order: ${props.$mobileOrder}`};
  }

  .alignment-centre {
    text-align: center;
  }

  .container {
    ${(props) => props.$containerStyle && { ...props.$containerStyle }};

    @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      width: inherit;
      padding: 40px 24px;

      &.narrow {
        padding: 56px 40px;
        text-align: center;
      }
    }
  }

  h3 {
    font-family: ${(props) => props.theme.v2.font.nantes};
    font-size: ${(props) => props.theme.v2.typography.display.xs.fontSize};
    line-height: ${(props) => props.theme.v2.typography.display.xs.lineHeight};
    margin-bottom: 16px;
    margin-top: 0;

    &.small-title {
      font-size: ${(props) => props.theme.v2.typography.display.xxs.fontSize};
      line-height: ${(props) => props.theme.v2.typography.display.xxs.lineHeight};
      margin-bottom: 32px;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      font-size: ${(props) => props.theme.v2.typography.body.xl.fontSize};
      line-height: ${(props) => props.theme.v2.typography.body.xl.lineHeight};

      &.small-title {
        font-size: ${(props) => props.theme.v2.typography.body.xl.fontSize};
        line-height: ${(props) => props.theme.v2.typography.body.xl.lineHeight};
        margin-bottom: 24px;
      }
    }
  }

  h2 {
    font-family: ${(props) => props.theme.v2.font.lotaGrotesqueLight};
    font-size: ${(props) => props.theme.v2.typography.body.m.fontSize};
    line-height: ${(props) => props.theme.v2.typography.body.m.lineHeight};
    margin-bottom: 0;
    margin-top: 0;

    &.category {
      color: ${(props) => props.theme.v2.onSurface.subduedText};
      margin-bottom: 16px;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      font-size: ${(props) => props.theme.v2.typography.body.m.fontSize};
      line-height: ${(props) => props.theme.v2.typography.body.m.lineHeight};

      &.category {
        margin-bottom: 8px;
      }
    }
  }

  p {
    font-family: ${(props) => props.theme.v2.font.lotaGrotesqueLight};
    font-size: ${(props) => props.theme.v2.typography.body.m.fontSize};
    line-height: ${(props) => props.theme.v2.typography.body.m.lineHeight};
    margin-bottom: 0;
    margin-top: 0;

    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      font-size: ${(props) => props.theme.v2.typography.body.m.fontSize};
      line-height: ${(props) => props.theme.v2.typography.body.m.lineHeight};

      &.small-description {
        font-size: ${(props) => props.theme.v2.typography.body.xs.fontSize};
        line-height: ${(props) => props.theme.v2.typography.body.xs.lineHeight};
      }
    }
  }

  a.button {
    margin-top: 40px;

    &:first-child {
      margin-bottom: 16px;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      margin-top: 24px;
    }
  }

  &#image-list {
    padding: 10px;
    h3 {
      @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
        font-size: ${(props) => props.theme.v2.typography.body.xl.fontSize};
        line-height: ${(props) => props.theme.v2.typography.body.xl.lineHeight};
      }
      @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
        font-size: ${(props) => props.theme.v2.typography.display.xs.fontSize};
        line-height: ${(props) => props.theme.v2.typography.display.xs.lineHeight};
      }
      @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        font-size: ${(props) => props.theme.v2.typography.body.xl.fontSize};
        line-height: ${(props) => props.theme.v2.typography.body.xl.lineHeight};
      }
    }
  }
`
const StyledListWithTitleBlock = styled.div`
  margin-top: 40px;
`

const trackButtonClick = (btnName: string) => {
  trackCtaClick('cms', btnName)
}

const renderBlock = (record, customId) => {
  switch (record.__typename) {
    case 'ListWithTitleRecord':
      return (
        <StyledListWithTitleBlock>
          <ListWithTitleBlock record={record} customId={customId} />
        </StyledListWithTitleBlock>
      )
    case 'TextWithIconRecord':
      return <TextWithIconBlock record={record} />
    default:
      return null
  }
}

export const TextDescription = ({
  record,
  containerStyle,
  mobileOrder,
}: {
  record: TextDescriptionRecord
  containerStyle?: CSSObject
  mobileOrder?: number
}) => {
  const customId = record.customId
  return (
    <StyledTextDescription
      $containerStyle={containerStyle}
      $mobileOrder={mobileOrder}
      id={customId}
      className="text-description-container"
    >
      <div className={`${record.alignment ? `text-description alignment-${record.alignment}` : 'text-description'}`}>
        <div className={`container ${record.category ? 'narrow' : null} `}>
          {record.category ? (
            <>
              <h2 className="category">{record.category}</h2>
              <h3 className="small-title">{renderHTML(record.title)}</h3>
            </>
          ) : (
            <h3>{renderHTML(record.title)}</h3>
          )}

          <div className={`${record.category ? 'small-description' : null}`}>
            <StructuredText data={record.content} renderBlock={({ record }) => renderBlock(record, customId)} />
          </div>

          {record.button.length > 0 && (
            <Button
              key={record.button[0].label}
              url={record.button[0].link}
              text={record.button[0].label}
              onClick={() => trackButtonClick(record.button[0].label)}
              style={record.button[0].buttonType}
            />
          )}
        </div>
      </div>
    </StyledTextDescription>
  )
}
