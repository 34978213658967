import { defaultPropsV2, IconProps } from './IconProps'

export const ChevronDown = (props: IconProps) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.75195 4.54248L5.78246 8.57299C5.89962 8.69015 6.08957 8.69015 6.20673 8.57299L10.2372 4.54248"
        stroke={props.colour}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
ChevronDown.defaultProps = defaultPropsV2
