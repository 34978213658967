import { Button } from '@/components/shared/Button'
import { ChevronDown } from '@/components/shared/icons/ChevronDown'
import { trackCtaClick } from '@/lib/gtm/gtmV2'
import { useCheckoutCountry } from '@/lib/hooks/useCheckoutCountry'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import styled from 'styled-components'
import { theme } from 'styles/theme'
import { HomePageRecord } from 'types'

type HomeFeaturedBannerProps = {
  pageContent: HomePageRecord
  setDisplayFeaturedBanner: Dispatch<SetStateAction<boolean>>
  displayFeaturedBanner: Boolean
  maxHeight?: number // for adjusting the height of the banner in animation
}

const StyledBanner = styled.div<{ $backgroundColour?: string }>`
  ${(p) => p.$backgroundColour && `background-color: ${p.$backgroundColour};`}
  display: flex;
  align-items: center;
  justify-content: center;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .delayed-fade-in {
    opacity: 0;
    animation: fadeIn 0.4s ease-in-out forwards;
    animation-delay: 0.4s;
  }

  p {
    margin: 0;
    padding: 0;
  }
`

const StyledBannerInner = styled.div<{ $maxHeight?: number }>`
  max-height: ${(props) => props.$maxHeight || 1000}px;
  transition: max-height 0.8s ease-in-out;

  display: flex;
  padding: 24px;
  max-width: 1300px;
  align-self: center;
  align-items: center;
  justify-content: center;
  font-family: ${(p) => p.theme.v2.font.lotaGrotesqueRegular};
  color: ${(p) => p.theme.v2.surface.white};
  font-size: ${(p) => p.theme.v2.typography.body.xs.fontSize};
  line-height: ${(p) => p.theme.v2.typography.body.xs.lineHeight};

  a {
    color: ${(p) => p.theme.v2.surface.white};
    margin-left: 24px;
    text-decoration: none;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 8px;
  }
`

const StyledBannerContent = styled.div<{ $expanded?: boolean }>`
  margin-right: 32px;
  flex-shrink: 1;
  flex-grow: ${(props) => (props.$expanded ? 1 : 0)};

  @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
    margin-right: 0;
  }
`

const StyledBannerExpandedContent = styled.div`
  font-family: ${(p) => p.theme.v2.font.lotaGrotesqueLight};

  @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
    margin-top: 12px;
    margin-bottom: 18px;
  }
`

const StyledBannerClickArea = styled.div`
  cursor: pointer;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;

  a {
    padding: 16px 32px;
    margin: 0;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
    width: 100%;
  }
`

const StyledBannerClickToExpandText = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: ${(p) => p.theme.v2.font.lotaGrotesqueSemiBold};
  font-size: ${(p) => p.theme.v2.typography.label.m.fontSize};

  @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
    font-family: ${(p) => p.theme.v2.font.lotaGrotesqueLight};
  }
`

const StyledButton = styled.div`
  display: flex;
  flex-direction: column;
`

export const HomeFeaturedBanner: React.FC<HomeFeaturedBannerProps> = ({
  pageContent,
  setDisplayFeaturedBanner,
  displayFeaturedBanner,
  maxHeight,
}) => {
  const { isReady, checkoutCountry, defaultCheckoutCountry } = useCheckoutCountry()
  const {
    featuredBannerBackgroundColour,
    featuredBannerCopy,
    featuredBannerContentExpanded,
    featuredBannerCtaText,
    featuredBannerCtaUrl,
    featuredBannerEnabled,
  } = pageContent

  const [bannerExpanded, setBannerExpanded] = useState(false)

  const ctaExpandBannerHandler = () => {
    if (featuredBannerCopy) {
      setBannerExpanded(true)
      trackCtaClick('homepage', 'homepage-banner-expand Read more')
    }
  }

  const ctaButtonClickHandler = () => {
    if (featuredBannerCtaText) {
      trackCtaClick('homepage', featuredBannerCtaText)
    }
  }

  useEffect(() => {
    const country = checkoutCountry || defaultCheckoutCountry

    if (isReady) {
      const displayCountry = pageContent.featuredBannerLocalisation[0]?.country
      setDisplayFeaturedBanner((displayCountry === country || displayCountry === 'all') && featuredBannerEnabled)
    }
  }, [isReady, checkoutCountry, defaultCheckoutCountry, pageContent, featuredBannerEnabled])

  return (
    <>
      {displayFeaturedBanner && (
        <StyledBanner $backgroundColour={featuredBannerBackgroundColour && featuredBannerBackgroundColour.hex}>
          {bannerExpanded ? (
            <StyledBannerInner className="delayed-fade-in">
              <StyledBannerContent $expanded>
                {featuredBannerCopy}
                <StyledBannerExpandedContent>{featuredBannerContentExpanded}</StyledBannerExpandedContent>
              </StyledBannerContent>
              <StyledBannerClickArea>
                <StyledButton>
                  <Button
                    key={featuredBannerCtaText}
                    url={featuredBannerCtaUrl}
                    text={featuredBannerCtaText}
                    style="white"
                    onClick={ctaButtonClickHandler}
                  />
                </StyledButton>
              </StyledBannerClickArea>
            </StyledBannerInner>
          ) : (
            <StyledBannerInner $maxHeight={maxHeight}>
              <StyledBannerContent>{featuredBannerCopy}</StyledBannerContent>
              <StyledBannerClickArea>
                <StyledBannerClickToExpandText onClick={ctaExpandBannerHandler}>
                  Read more
                  <ChevronDown colour={theme.colours.white} />
                </StyledBannerClickToExpandText>
              </StyledBannerClickArea>
            </StyledBannerInner>
          )}
        </StyledBanner>
      )}
    </>
  )
}
