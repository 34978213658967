import { elementInViewport } from '@/lib/utils/visibility'
import React, { useEffect, useRef, useState } from 'react'
import { StructuredText } from 'react-datocms'
import styled from 'styled-components'
import { ButtonRecord, HomePageArticlesLinkRecord, LinkListRecord } from 'types'
import { ButtonBlock } from './ButtonBlock'
import { LinkListBlock } from './LinkListBlock'

const StyledSplitContainer = styled.div<{ $bgColor: string }>`
  background-color: ${(props) => props.$bgColor};
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 80px 0;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: column;
    padding: 64px 0;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 56px 0 48px;
  }
`

const StyledLeftContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: 100%;
    padding: 0 100px;
    justify-content: flex-start;
    align-items: flex-start;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0 24px;
  }

  .button {
    display: block;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      display: none;
    }
  }

  h2 {
    font-size: ${(props) => props.theme.v2.typography.display.l.fontSize};
    line-height: ${(props) => props.theme.v2.typography.display.l.lineHeight};
    font-family: ${(props) => props.theme.v2.font.nantes};
    color: ${(props) => props.theme.v2.surface.dark};
    margin: 0 0 40px;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      font-size: ${(props) => props.theme.v2.typography.display.xs.fontSize};
      line-height: ${(props) => props.theme.v2.typography.display.xs.lineHeight};
    }
  }
`

const StyledLeftInnerContainer = styled.div`
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    max-width: unset;
  }
`

const StyledRightContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 100px 0 40px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 100px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0 24px;
  }

  .button {
    display: none;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      display: block;
    }
  }

  ul {
    max-width: 600px;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      max-width: unset;
    }
  }
`

const StyledTextOverlay = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  p {
    font-size: ${(props) => props.theme.v2.typography.display.xxxl.fontSize};
    line-height: ${(props) => props.theme.v2.typography.display.xxxl.lineHeight};
    font-family: ${(props) => props.theme.v2.font.nantes};
    color: ${(props) => props.theme.v2.surface.white};
    padding: 0;
    margin: 0;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      font-size: ${(props) => props.theme.v2.typography.display.l.fontSize};
      line-height: ${(props) => props.theme.v2.typography.display.l.lineHeight};
    }
  }

  @keyframes strike {
    0% {
      width: 0;
    }
    20% {
      width: 100%;
    }
    100% {
      width: 100%;
    }
  }

  @keyframes show {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  .strike {
    position: relative;
    transition: all 0.24s cubic-bezier(1, 0, 0.58, 0.97);
  }

  .strike::before {
    content: '';
    width: 0;
    position: absolute;
    height: 3px;
    top: 50%;
    left: 0;
    background: white;
    transition: inherit;
  }

  .show {
    opacity: 0;
    overflow: hidden;
    white-space: nowrap;
    margin: 0 auto;
  }

  &.visible {
    .strike::before {
      animation: strike 5s;
      animation-fill-mode: forwards;
    }

    .show {
      animation: show 5s;
      animation-fill-mode: forwards;
    }
  }
`

const StyledImageContainer = styled.div`
  width: 100%;
  height: auto;
  position: relative;
`

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  content: url('/assets/img/homepage/abundance-large.jpg');

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    content: url('/assets/img/homepage/abundance-small.jpg');
  }
`

type HomePageArticlesLinkProps = {
  record: HomePageArticlesLinkRecord
}

const ImageWithTextOverlay: React.FC<HomePageArticlesLinkProps> = ({ record }) => {
  const overlayRef = useRef<HTMLDivElement | null>(null)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler)

    return () => window.removeEventListener('scroll', scrollHandler)
  }, [])

  const scrollHandler = () => {
    if (overlayRef?.current && elementInViewport(overlayRef.current)) {
      setVisible(true)
    }
  }

  return (
    <StyledImageContainer>
      <StyledImage alt="3 happy women having nibbles in a park setting" />

      <StyledTextOverlay ref={overlayRef} className={visible ? 'visible' : undefined}>
        {record.imageOverlayLine1 && (
          <div className="strike">
            <StructuredText data={record.imageOverlayLine1} />
          </div>
        )}
        {record.imageOverlayLine2 && (
          <div className="show">
            <StructuredText data={record.imageOverlayLine2} />
          </div>
        )}
      </StyledTextOverlay>
    </StyledImageContainer>
  )
}

export const HomePageArticlesLink: React.FC<HomePageArticlesLinkProps> = ({ record }) => {
  const button = record.content.find((c) => c['__typename'] === 'ButtonRecord') as ButtonRecord
  const links = record.content.find((c) => c['__typename'] === 'LinkListRecord') as LinkListRecord

  return (
    <div>
      <ImageWithTextOverlay record={record} />

      <StyledSplitContainer $bgColor={record.backgroundColor}>
        <StyledLeftContainer>
          <StyledLeftInnerContainer>
            <StructuredText data={record.title} />

            <ButtonBlock record={button} />
          </StyledLeftInnerContainer>
        </StyledLeftContainer>

        <StyledRightContainer>
          <LinkListBlock record={links} />

          <ButtonBlock record={button} />
        </StyledRightContainer>
      </StyledSplitContainer>
    </div>
  )
}
