import { Footer } from '@/components/elements/footer/Footer'
import { Header } from '@/components/elements/header/Header'
import { HomeFeaturedBanner } from '@/components/pages/home/HomeFeaturedBanner'
import { HomeContent } from '@/components/pages/home/HomeContent'
import { buildZoeDataQueryStringMap, ZOE_UTM_COOKIES_WHITELIST } from '@/lib/utils/utmData'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import { HomePageRecord } from 'types'
import { HomeHeroSectionOriginal } from '@/components/pages/home/HomeHeroSectionOriginal'

type HomePageProps = {
  pageContent: HomePageRecord
}

export const HomePage: React.FC<HomePageProps> = ({ pageContent }) => {
  const router = useRouter()
  const [cookies] = useCookies(ZOE_UTM_COOKIES_WHITELIST)
  const [pageUrl, setPageUrl] = useState(process.env.SITE_URL || '') // Must be a string. If not initially set, will be undefined, causing errors.
  const [queryParameters] = useState(buildZoeDataQueryStringMap(cookies))
  const [displayFeaturedBanner, setDisplayFeaturedBanner] = useState(false)

  // window is only accessible inside a useEffect.
  useEffect(() => {
    setPageUrl(window.location.href)
  }, [])

  // get the height of HomeFeaturedBanner and pass it into Header to adjust the menu position
  const bannerRef = useRef<HTMLDivElement>(null)
  const [bannerHeight, setBannerHeight] = useState<number | null>(null)

  useEffect(() => {
    if (bannerRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          setBannerHeight(entry.target.clientHeight)
        }
      })

      resizeObserver.observe(bannerRef.current)

      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [])

  return (
    <>
      <Head>
        <title>{pageContent.metaData.title}</title>
        <meta name="description" content={pageContent.metaData.description} />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta property="og:title" content={pageContent.metaData.title} key="title" />
        <meta property="og:description" content={pageContent.metaData.description} key="description" />
        <meta property="og:image" content={pageContent.metaData.image.responsiveImage.src} key="image" />
        <meta property="og:url" content={`${process.env.SITE_URL}${router.asPath}`} key="url" />
        <meta property="og:type" content="website" key="type" />
        <meta name="twitter:title" content={pageContent.metaData.title} />
        <meta name="twitter:description" content={pageContent.metaData.description} />
        <meta name="twitter:image" content={pageContent.metaData.image.responsiveImage.src} />
        <meta name="twitter:site" content="@Join_ZOE" />
        <meta name="twitter:card" content={pageContent.metaData.twitterCard} />
      </Head>
      <div ref={bannerRef}>
        <HomeFeaturedBanner
          pageContent={pageContent}
          setDisplayFeaturedBanner={setDisplayFeaturedBanner}
          displayFeaturedBanner={displayFeaturedBanner}
          maxHeight={bannerHeight || undefined}
        />
      </div>
      <Header iconColour="dark" featuredBanner={displayFeaturedBanner} bannerHeight={bannerHeight} />
      <main id="main-content">
        <HomeHeroSectionOriginal
          content={pageContent.heroSection}
          pageUrl={pageUrl}
          queryParameters={queryParameters}
        />
        <HomeContent content={pageContent.content} />
      </main>
      <Footer showEmailSignUp={false} />
    </>
  )
}
