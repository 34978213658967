import styled from 'styled-components'
import { LinkListRecord } from 'types'
import { LinkBlock } from './LinkBlock'

const StyledList = styled.ul`
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
`

const StyledListItem = styled.li`
  position: relative;

  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.v2.surface.dark};
  }

  a {
    font-size: ${(props) => props.theme.v2.typography.body.m.fontSize};
    line-height: ${(props) => props.theme.v2.typography.body.m.lineHeight};
    font-family: ${(props) => props.theme.v2.font.lotaGrotesqueRegular};
    color: ${(props) => props.theme.v2.surface.dark};
    display: inline-block;
    width: 100%;
    padding: 40px 80px 40px 0;

    &:hover {
      color: ${(props) => props.theme.v2.secondary.pressed};
    }

    @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      font-size: ${(props) => props.theme.v2.typography.body.xs.fontSize};
      line-height: ${(props) => props.theme.v2.typography.body.xs.lineHeight};
    }
  }
`

const StyledArrowImg = styled.span`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 26px;
  height: 20px;
  background-image: url('/assets/img/icons/link-arrow-black.svg');
  background-size: contain;
  background-repeat: no-repeat;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 20px;
    height: 15px;
  }
`

export const LinkListBlock = ({ record }: { record: LinkListRecord }) => {
  return (
    <StyledList>
      {record.links.map((link) => (
        <StyledListItem key={link.id}>
          <LinkBlock record={link} />

          <StyledArrowImg />
        </StyledListItem>
      ))}
    </StyledList>
  )
}
