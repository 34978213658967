import styled from 'styled-components'

import { Button } from '@/components/shared/Button'
import { BjmGutLogo } from '@/components/shared/icons/logos/BjmGutLogo'
import { NatureLogo } from '@/components/shared/icons/logos/NatureLogo'
import { ScienceLogo } from '@/components/shared/icons/logos/ScienceLogo'
import { TheLancetLogo } from '@/components/shared/icons/logos/TheLancetLogo'
import { trackCtaClick } from '@/lib/gtm/gtmV2'
import Image from 'next/image'
import Link from 'next/link'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  text-align: center;
  align-items: center;
  background: ${(p) => p.theme.v2.surface.cream100};
  max-width: 1200px;
  margin: 0 auto;
  border-radius: 24px;
  padding: 72px 32px;
  margin-top: 56px;

  h2 {
    max-width: 880px;
    margin: 0 0 16px 0;
    font-family: ${(p) => p.theme.v2.font.nantes};
    font-size: 32px;
    line-height: 44px;

    @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 26px;
      line-height: 34px;
    }

    em {
      font-family: ${(p) => p.theme.v2.font.nantesItalic};
    }
  }

  p {
    max-width: 620px;
    margin: 0;
    font-family: ${(p) => p.theme.v2.font.lotaGrotesqueLight};
    font-size: ${(p) => p.theme.v2.typography.body.xs.fontSize};
    line-height: ${(p) => p.theme.v2.typography.body.xs.lineHeight};

    @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: ${(p) => p.theme.v2.typography.body.s.fontSize};
      line-height: ${(p) => p.theme.v2.typography.body.s.lineHeight};
    }
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding: 72px 16px;
    margin-top: 0;
    border-radius: 0 0 24px 24px;
  }
`

const StyledRow = styled.div`
  display: flex;
  gap: 48px;
  justify-content: center;
  margin-top: 40px;

  svg {
    height: 36px;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    flex-direction: column;
    gap: 32px;
    align-items: center;

    svg {
      height: 28px;
    }
  }
`

const StyledRowCharts = styled.div`
  display: flex;
  gap: 9px;
  justify-content: center;
  margin: 40px 0 12px 0;

  svg {
    height: 36px;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    align-items: center;
    width: 100%;
  }
`

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    background-color: ${(p) => p.theme.v2.surface.white};
    padding: 24px;
    border-radius: 24px;
  }
`

const StyledSeparator = styled.hr`
  background-color: ${(p) => p.theme.v2.surface.dark};
  margin: 72px 0;
  width: 100%;
  height: 1px;
  border: none;
  opacity: 0.2;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    background-color: transparent;
    margin: 12px 0;
  }
`

const StyledCaption = styled.div`
  font-size: ${(p) => p.theme.v2.typography.label.m.fontSize};
  line-height: ${(p) => p.theme.v2.typography.label.m.lineHeight};
  margin-top: 12px;
`

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 48px;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-top: 32px;
  }
`

const StyledChart = styled.div`
  background-color: ${(p) => p.theme.v2.surface.white};
  padding: 16px 12px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 300;
  border-radius: 8px;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    background-color: ${(p) => p.theme.v2.surface.cream100};
    width: 50%;
    padding: 16px 8px;
  }
`

const trackButtonClick = (btnName: string) => {
  trackCtaClick('homepage', btnName)
}

export const ScienceSection = () => {
  const logoColor = '#2D3246'
  const buttonLabel = 'Get started'

  return (
    <StyledContainer>
      <StyledColumn>
        <h2>
          Our scientists have written 100+ <em>peer-reviewed papers</em>
        </h2>
        <p>ZOE has a world leading science team that publish papers in the top medical journals.</p>
        <StyledRow>
          <NatureLogo colour={logoColor} />
          <ScienceLogo colour={logoColor} />
          <img src="/assets/img/logos/gut-logo.png" alt="BMJ Gut Journal" width="124" height="49" />
          <TheLancetLogo colour={logoColor} />
        </StyledRow>
      </StyledColumn>

      <StyledSeparator />

      <StyledColumn>
        <h2>
          Our clinical trial <em>proves</em> ZOE works
        </h2>
        <p>
          We’ve shown that following ZOE significantly improves key health markers.{' '}
          <Link href="https://zoe.com/learn/does-zoe-work-evidence">Read more.</Link>
        </p>
        <StyledRowCharts>
          <StyledChart>
            <Image src="/assets/img/homepage/science-bar-chart.svg" width={62} height={62} alt="" />
            <StyledCaption>Blood fat decrease</StyledCaption>
          </StyledChart>
          <StyledChart>
            <Image src="/assets/img/homepage/science-bar-chart.svg" width={62} height={62} alt="" />
            <StyledCaption>Waist circumference lost</StyledCaption>
          </StyledChart>
        </StyledRowCharts>
        <StyledCaption>ZOE vs. General nutrition advice</StyledCaption>
        <StyledButtonWrapper>
          <Button
            url="/quiz"
            text={buttonLabel}
            style="primary"
            onClick={() => trackButtonClick(`${buttonLabel}-science-section`)}
          />
        </StyledButtonWrapper>
      </StyledColumn>
    </StyledContainer>
  )
}
