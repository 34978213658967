import moment from 'moment'
import styled from 'styled-components'
import { DatoImage } from '@/elements/global/DatoImage'
import { TrustpilotLogo } from './TrustpilotLogo'
import { TrustpilotStarRating } from './TrustpilotStarRating'
import { useEffect, useState } from 'react'

export type Review = {
  id: string
  authorName: string
  authorImageUrl?: string
  rating: number
  title: string
  reviewText: string
  reviewLink: string
  publishedTime: string
}

export interface ReviewProps {
  review: Review
  style?: string
  reviewLength?: number
  showDate?: boolean
}

const truncateText = (text: string, length: number): string => {
  const truncated = text.substring(0, length + 1)
  const lastSpace = truncated.lastIndexOf(' ')
  return truncated.substring(0, lastSpace)
}

const StyledReview = styled.section`
  background-color: ${(props) => props.theme.v2.surface.white};
  border-radius: 16px;
  padding: 24px;

  &.bg-white {
    background-color: ${(props) => props.theme.v2.surface.cream500};
  }

  &.bg-cream {
    background-color: ${(props) => props.theme.v2.surface.white};
  }

  .review-header {
    display: flex;
  }

  .author {
    flex: 1;
  }

  .avatar {
    display: inline-block;
    position: relative;
    height: 48px;
    width: 48px;
    border-radius: 24px;
    overflow: hidden;
    background-color: #dadde7;
    vertical-align: middle;
  }

  .avatar-alt {
    display: inline-block;
    width: 100%;
    text-align: center;

    font-size: ${(props) => props.theme.v2.typography.body.xs.fontSize};
    line-height: ${(props) => props.theme.v2.typography.body.xs.lineHeight};

    position: absolute;
    top: 26%;
  }

  .author-name {
    margin: 0 16px;
    font-family: ${(props) => props.theme.v2.font.lotaGrotesqueRegular};
    font-size: ${(props) => props.theme.v2.typography.body.xs.fontSize};
    line-height: ${(props) => props.theme.v2.typography.body.xs.lineHeight};
  }

  .review-rating {
    margin: 16px 0;

    div {
      justify-content: flex-start;
    }
  }

  .review-block {
    height: 172px;
    overflow: hidden;
  }

  .review-text,
  .review-title {
    font-size: ${(props) => props.theme.v2.typography.body.xs.fontSize};
    line-height: ${(props) => props.theme.v2.typography.body.xs.lineHeight};
    color: ${(props) => props.theme.v2.onSurface.text};
    margin-top: 8px;
  }

  .review-title {
    font-family: ${(props) => props.theme.v2.font.lotaGrotesqueSemiBold};
    margin: 0;
  }

  .review-publish-date {
    font-family: ${(props) => props.theme.v2.font.lotaGrotesqueLight};
    font-size: ${(props) => props.theme.v2.typography.body.xxs.fontSize};
    line-height: ${(props) => props.theme.v2.typography.body.xxs.lineHeight};
    color: ${(props) => props.theme.v2.onSurface.subtext};
  }

  .review-link {
    color: ${(props) => props.theme.v2.interactive.bright};

    &:hover {
      color: ${(props) => props.theme.v2.interactive.subdued};
    }
  }
`

export const TrustpilotReview = ({ review, style, reviewLength = 170, showDate = true }: ReviewProps) => {
  const [reviewDate, setReviewDate] = useState<string>('')
  const authorInitials = review.authorName
    .split(' ')
    .map((name) => name.substring(0, 1))
    .join('')

  const shouldTruncateReviewText = review.reviewText.length > reviewLength
  const shortReviewText = shouldTruncateReviewText
    ? truncateText(review.reviewText, reviewLength - 2) + ' …'
    : review.reviewText

  const styleClass = style ? `bg-${style}` : ''

  useEffect(() => {
    // Workaround for Next.js minified React hydration errors. Happens to date calculations
    // when the server is in a different timezone to the customer.
    // See: https://github.com/vercel/next.js/discussions/39425
    // Using a useEffect means we can use `window` to check we are in a browser, and then
    // format the date.
    if (window) {
      setReviewDate(moment(new Date(review.publishedTime)).format('HH:mm · d MMMM'))
    }
  }, [review.publishedTime])

  return (
    <StyledReview className={styleClass}>
      <div className="review-header">
        <span className="author">
          <span className="avatar">
            {!!review.authorImageUrl ? (
              <DatoImage data={{ src: review.authorImageUrl, alt: authorInitials, height: 48, width: 48 }} />
            ) : (
              <span className="avatar-alt">{authorInitials}</span>
            )}
          </span>
          <span className="author-name">{review.authorName}</span>
        </span>
        <TrustpilotLogo />
      </div>
      <div className="review-rating">
        <TrustpilotStarRating rating={review.rating} />
      </div>
      <div className="review-block">
        <h3 className="review-title">{review.title}</h3>
        <p className="review-text">
          {shortReviewText}
          {shouldTruncateReviewText && (
            <a href={review.reviewLink} className="review-link" target="_blank" rel="noreferrer">
              read more
            </a>
          )}
        </p>
      </div>
      {showDate && <div className="review-publish-date">{reviewDate}</div>}
    </StyledReview>
  )
}
