import { LogoDropdown } from '@/components/blocks/LogoDropdown/LogoDropdown'
import { DatoImage } from '@/elements/global/DatoImage'
import { StructuredText } from 'react-datocms'
import styled from 'styled-components'
import { ZoePodcastRecord } from 'types'

const StyledPodcastSection = styled.div`
  color: ${(props) => props.theme.v2.onSurface.text};
  height: 700px;
  background-image: url('/assets/img/backgrounds/podcast-bg.png');
  background-size: cover;
  background-position: center top;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    background-image: url('/assets/img/backgrounds/podcast-bg-tablet.png');
    min-height: 735px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    background-image: url('/assets/img/backgrounds/podcast-bg-mobile.jpg');
    min-height: 815px;
  }

  h3 {
    font-family: ${(props) => props.theme.v2.font.nantes};
    font-size: ${(props) => props.theme.v2.typography.display.xs.fontSize};
    line-height: ${(props) => props.theme.v2.typography.display.xs.lineHeight};
    margin: 0;
    padding: 0;
    text-align: center;

    em {
      font-family: ${(p) => p.theme.v2.font.nantesItalic};
    }

    @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      font-size: ${(props) => props.theme.v2.typography.body.xl.fontSize};
      line-height: ${(props) => props.theme.v2.typography.body.xl.lineHeight};
    }
  }

  h6 {
    font-family: ${(props) => props.theme.v2.font.lotaGrotesqueLight};
    font-size: ${(props) => props.theme.v2.typography.body.s.fontSize};
    line-height: ${(props) => props.theme.v2.typography.body.s.lineHeight};
    margin-bottom: 56px;
    margin-top: 16px;
    padding: 0;
    text-align: center;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      font-size: ${(props) => props.theme.v2.typography.body.xs.fontSize};
      line-height: ${(props) => props.theme.v2.typography.body.xs.lineHeight};
    }
  }

  p {
    color: ${(props) => props.theme.v2.onSurface.subduedText};
    font-size: ${(props) => props.theme.v2.typography.body.s.fontSize};
    line-height: ${(props) => props.theme.v2.typography.body.s.lineHeight};
    margin: 0;
    margin-bottom: 16px;
  }
`

const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 120px 48px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 100px 48px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 56px 24px;
  }

  .podcast-logo {
    margin-bottom: 56px;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      margin-bottom: 32px;
    }
  }
`

const renderBlock = (record) => {
  switch (record.__typename) {
    case 'LogoDropdownRecord':
      return <LogoDropdown record={record} />
    default:
      return null
  }
}

export const PodcastSection = ({ record }: { record: ZoePodcastRecord }) => {
  return (
    <StyledPodcastSection>
      <StyledContainer>
        <DatoImage data={record.logo.responsiveImage} className="podcast-logo" />
        <StructuredText data={record.content} renderBlock={({ record }) => renderBlock(record)} />
      </StyledContainer>
    </StyledPodcastSection>
  )
}
