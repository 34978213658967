import { FragmentComponent } from '@/types/graphql'
import React from 'react'
import { StructuredText } from 'react-datocms'
import styled from 'styled-components'
import { TextWithIconRecord } from 'types'

const StyledTextWithIconBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .icon {
    height: 24px;
    width: 40px;
  }
`

const StyledText = styled.div`
  font-family: ${(props) => props.theme.v2.font.lotaGrotesqueRegular};
  font-size: ${(props) => props.theme.v2.typography.body.xs.fontSize};
  line-height: ${(props) => props.theme.v2.typography.body.xs.lineHeight};
  color: ${(props) => props.theme.v2.surface.dark};
  font-weight: 400;
`

const fragment = `
  fragment TextWithIconBlockFragment on TextWithIconRecord {
    __typename
    id
    icon {
      id
      url
      alt
    }
    content {
      value
    }
  }
`

export const TextWithIconBlock: FragmentComponent<{}, TextWithIconRecord> = ({ record }) => (
  <StyledTextWithIconBlock className="text-with-icon">
    <img src={record.icon.url} alt={record.icon.alt} className="icon" />
    <StyledText>
      <StructuredText data={record.content} />
    </StyledText>
  </StyledTextWithIconBlock>
)
TextWithIconBlock.fragment = fragment
TextWithIconBlock.recordName = 'TextWithIconRecord'
