import { ImageFullContainer } from '@/components/blocks/ImageFullContainer'
import { TextDescription } from '@/components/blocks/TextDescription'
import { ReactNode } from 'react'
import styled, { CSSObject } from 'styled-components'
import { ImageAndTextBlockRecord, ImageAndTextRecord } from 'types'

type ImageAndTextProps = {
  record: ImageAndTextRecord | ImageAndTextBlockRecord
  containerStyle?: CSSObject
  imageSubContainerStyle?: CSSObject
  textSubContainerStyle?: CSSObject
  customImage?: ReactNode
  customTextOverlay?: ReactNode
}

const StyledImageAndTextBlock = styled.div<{ $containerStyle?: CSSObject }>`
  display: flex;
  flex-direction: row;

  > * {
    flex: 1;
  }

  ${(props) => props.$containerStyle && { ...props.$containerStyle }}

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: column;
  }
`

export const ImageAndText = ({
  record,
  containerStyle,
  imageSubContainerStyle,
  textSubContainerStyle,
  customImage,
  customTextOverlay,
}: ImageAndTextProps) => {
  const imageOnLeft = record.leftSection[0].image !== undefined

  return imageOnLeft ? (
    <StyledImageAndTextBlock $containerStyle={containerStyle}>
      <ImageFullContainer
        record={record.leftSection[0]}
        containerStyle={imageSubContainerStyle}
        customImage={customImage}
        customTextOverlay={customTextOverlay}
        rounded={record.style === 'narrow'}
      />
      <TextDescription record={record.rightSection[0]} containerStyle={textSubContainerStyle} />
    </StyledImageAndTextBlock>
  ) : (
    <StyledImageAndTextBlock $containerStyle={containerStyle} className="image-and-text-right">
      <TextDescription record={record.leftSection[0]} containerStyle={textSubContainerStyle} mobileOrder={2} />
      <ImageFullContainer
        record={record.rightSection[0]}
        containerStyle={imageSubContainerStyle}
        mobileOrder={1}
        customImage={customImage}
        customTextOverlay={customTextOverlay}
        rounded={record.style === 'narrow'}
      />
    </StyledImageAndTextBlock>
  )
}
