import React from 'react'
import styled from 'styled-components'
import { Container } from '@/components/shared/Container'
import { PageIntroRecord } from 'types'
import { StructuredText } from 'react-datocms'
import Image from 'next/image'

const StyledWrap = styled.section`
  padding: 56px 0;
  background-color: ${(p) => p.theme.v2.surface.cream100};
  border-radius: 24px;
  margin-top: -24px;
  position: relative;
  z-index: 1;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 16px 0;
  }
`

const StyledFlex = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 34px;
  text-align: center;
  gap: 24px;
  flex: 1;

  &:not(:last-child) {
    border-right: 1px solid ${(p) => p.theme.v2.surface.cream600};
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 32px 12px;
    &:not(:last-child) {
      border-bottom: 1px solid ${(p) => p.theme.v2.surface.cream600};
      border-right: 0;
    }
  }
`

const StyledHeading = styled.div`
  h2 {
    font-family: ${(p) => p.theme.v2.font.nantes};
    font-size: 32px;
    line-height: 40px;
    font-weight: 350;
    margin: 0;
    text-align: left;
  }

  mark {
    position: relative;
    background: none;
    display: inline-block;
    font-style: italic;
    font-family: 'Nantes-Italic';

    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 30%;
      bottom: 9%;
      background-color: ${(p) => p.theme.v2.custom.yellow200};
      z-index: -1;
    }
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    h2 {
      font-size: ${(p) => p.theme.v2.typography.display.xs.fontSize};
      line-height: ${(p) => p.theme.v2.typography.display.xs.lineHeight};
      text-align: left;
    }
  }
`

const StyledBullets = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-left: 0;
  }
`

const StyledBullet = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 32px 16px 0;
  font-family: ${(p) => p.theme.v2.font.lotaGrotesqueRegular};
  font-size: 19px;
  line-height: 30px;
  display: flex;
  gap: 16px;
  letter-spacing: 0.333px;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    text-align: left;
    padding: 16px 0;
    width: 100%;
    font-size: ${(p) => p.theme.v2.typography.body.m.fontSize};
    line-height: ${(p) => p.theme.v2.typography.body.m.lineHeight};
  }
`
const StyledImageAndParagraph = styled.div`
  display: flex;
  gap: 16px;

  p {
    font-family: ${(p) => p.theme.v2.font.lotaGrotesqueLight};
    font-size: 18px;
    line-height: 26px;
    text-align: left;
    max-width: 300px;
    margin: 0;
  }

  a {
    text-decoration: underline;
    font-family: ${(p) => p.theme.v2.font.lotaGrotesqueRegular};
    color: black;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    justify-content: flex-start;

    p {
      max-width: 100%;
      padding-right: 32px;
    }

    img {
      max-height: 123px;
      min-width: 90px;
    }
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
    p {
      padding-right: 0;
    }
  }
`

export const PageIntro = ({ record }: { record: PageIntroRecord }) => {
  const { heading } = record
  const bullets = record.bullets.split('|')

  return (
    <StyledWrap>
      <Container $size="wide" $flex $direction="row">
        <StyledFlex>
          <StyledHeading>
            <StructuredText data={heading} />
          </StyledHeading>
          <StyledBullets>
            {bullets.map((bullet) => (
              <StyledBullet key={bullet}>
                <Image width={29} height={28} src="assets/img/icons/bullet.svg" alt="" />
                {bullet}
              </StyledBullet>
            ))}
          </StyledBullets>
        </StyledFlex>
        <StyledFlex>
          <StyledHeading>
            <h2>
              Scientifically <mark>proven</mark> to work
            </h2>
          </StyledHeading>
          <StyledImageAndParagraph>
            <Image
              width={90}
              height={123}
              src="/assets/img/nature-cover-method.jpg"
              alt="Nature cover - method study"
            />
            <p>
              Our METHOD clinical trial confirms that ZOE&apos;s personalised approach to nutrition is better for your
              health than generalised dietary advice. &nbsp;<a href="/method">Learn more</a>
            </p>
          </StyledImageAndParagraph>
        </StyledFlex>
      </Container>
    </StyledWrap>
  )
}
