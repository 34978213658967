/* eslint-disable jsx-a11y/alt-text */
import { Container } from '@/components/shared/Container'
import { Image, StructuredText } from 'react-datocms'
import styled from 'styled-components'
import type { OffsetGridRecord } from 'types'
import { useWindowSize } from '@/lib/hooks/useWindowSize'
import { Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.css'
import 'swiper/css/pagination'
import { Button } from '../shared/Button'

type OffsetGridProps = {
  record: OffsetGridRecord
}

const StyledOffsetGrid = styled.div`
  margin: 80px 0;

  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0;
  }

  .swiper-wrapper {
    padding-bottom: 48px;
  }

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: ${(p) => p.theme.v2.surface.dark};
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
    margin: 60px 0;
  }
`

const StyledHeadline = styled.div`
  text-align: center;
  margin-bottom: 100px;

  h2 {
    font-size: 62px;
    line-height: 72px;
    margin: 80px 0 0 0;
    font-family: ${(p) => p.theme.v2.font.nantes};
    letter-spacing: -1.3px;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
    margin-bottom: 40px;

    h2 {
      margin: 40px 0 0 0;
      font-size: ${(p) => p.theme.v2.typography.display.xs.fontSize};
      line-height: ${(p) => p.theme.v2.typography.display.xs.lineHeight};
    }
  }
`

const StyledRow = styled.div<{ $flip?: boolean }>`
  display: flex;
  gap: 50px;
  flex-direction: ${(p) => (p.$flip ? 'row' : 'row-reverse')};
  margin-bottom: 24px;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
    flex-direction: column;
    gap: 24px;
  }
`

const StyledSlide = styled.div`
  background-color: ${(p) => p.theme.v2.surface.cream100};
  border-radius: 30px;
  overflow: hidden;
`

const StyledImage = styled.div`
  width: 50%;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
    width: 100%;
  }
`

const StyledText = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;

  p {
    font-family: ${(p) => p.theme.v2.font.lotaGrotesqueLight};
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
    width: 100%;
    padding: 24px;
    flex-direction: column;
    justify-content: flex-start;
  }
`

const StyledTextInner = styled.div`
  max-width: 438px;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
    max-width: none;
  }
`

const StyledSectionHeading = styled.div`
  display: flex;
  font-family: ${(p) => p.theme.v2.font.nantes};
  align-items: center;
  gap: 24px;
  margin-bottom: 32px;

  span {
    font-size: 94px;
    line-height: 64px;
  }

  h2 {
    font-size: ${(p) => p.theme.v2.typography.display.xs.fontSize};
    line-height: ${(p) => p.theme.v2.typography.display.xs.lineHeight};
    margin: 0;

    em {
      font-family: ${(p) => p.theme.v2.font.nantesItalic};
    }
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
    gap: 12px;
    margin-bottom: 0;

    span {
      font-size: 46px;
      line-height: 64px;
    }

    h2 {
      font-family: ${(p) => p.theme.v2.font.lotaGrotesqueRegular};
      font-size: ${(p) => p.theme.v2.typography.body.s.fontSize};
      line-height: ${(p) => p.theme.v2.typography.body.s.lineHeight};
    }
  }
`

const StyledCallToAction = styled.div`
  margin-top: 80px;
  text-align: center;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
    margin-top: 40px;
  }
`

export const OffsetGrid: React.FC<OffsetGridProps> = ({ record }) => {
  const { heading, sections, ctaText, ctaUrl } = record
  const { isMobile } = useWindowSize()

  return (
    <StyledOffsetGrid>
      <Container $size="wide">
        <StyledHeadline>
          <StructuredText data={heading} />
        </StyledHeadline>
        {isMobile ? (
          <Swiper modules={[Pagination]} pagination slidesPerView={1.2} spaceBetween={24}>
            {sections.map((section, i) => (
              <SwiperSlide key={section.id}>
                <StyledSlide>
                  <StyledImage>
                    <Image data={section.mobileImage.responsiveImage} />
                  </StyledImage>
                  <StyledText>
                    <StyledTextInner>
                      <StyledSectionHeading>
                        <span>{i + 1}</span>
                        <StructuredText data={section.heading} />
                      </StyledSectionHeading>
                    </StyledTextInner>
                    <StructuredText data={section.text} />
                  </StyledText>
                </StyledSlide>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <>
            {sections.map((section, i) => {
              return (
                <StyledRow key={section.id} $flip={i % 2 === 0}>
                  <StyledImage>
                    <Image data={section.image.responsiveImage} />
                  </StyledImage>
                  <StyledText>
                    <StyledTextInner>
                      <StyledSectionHeading>
                        <span>{i + 1}</span>
                        <StructuredText data={section.heading} />
                      </StyledSectionHeading>
                      <StructuredText data={section.text} />
                    </StyledTextInner>
                  </StyledText>
                </StyledRow>
              )
            })}
          </>
        )}
        <StyledCallToAction>
          <Button url={ctaUrl} text={ctaText} style="primary" />
        </StyledCallToAction>
      </Container>
    </StyledOffsetGrid>
  )
}
