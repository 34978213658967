/* eslint-disable jsx-a11y/alt-text */
import { Button } from '@/components/shared/Button'
import { Container } from '@/components/shared/Container'
import { trackCtaClick } from '@/lib/gtm/gtmV2'
import { useWindowSize } from '@/lib/hooks/useWindowSize'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { createRef, useEffect, useRef, useState } from 'react'
import { Image, StructuredText } from 'react-datocms'
import styled from 'styled-components'
import type { TwoColumnSlideRecord } from 'types'

gsap.registerPlugin(ScrollTrigger)

type TwoColumnSlidesProps = {
  record: TwoColumnSlideRecord
}

const StyledTwoColumnSlides = styled.div`
  width: 100%;
  margin: 80px 0;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
    margin: 60px 0 24px;
  }
`

const StyledHeadline = styled.div`
  text-align: center;
  margin-bottom: 100px;

  h1 {
    font-size: 62px;
    line-height: 72px;
    margin: 0;
    font-family: ${(p) => p.theme.v2.font.nantes};
    letter-spacing: -1.3px;

    em {
      font-family: ${(p) => p.theme.v2.font.nantesItalic};
    }
  }
  @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
    margin-bottom: 32px;
    h1 {
      font-size: ${(p) => p.theme.v2.typography.display.l.fontSize};
      line-height: ${(p) => p.theme.v2.typography.display.l.lineHeight};
    }
  }
`

const StyledRow = styled.div`
  display: flex;
  gap: 50px;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    gap: 20px;
  }
  @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
    flex-direction: column;
    gap: 24px;
  }
`

const StyledImageColumn = styled.div`
  width: 50%;
  align-self: flex-start;
  position: relative;
  min-height: 625px;
  z-index: 100;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    min-height: 450px;
    overflow: hidden;
    border-radius: 30px;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
    position: relative;
    width: 100%;
    padding-bottom: 0;
    top: 0;
    padding-bottom: 0;
    min-height: 350px;
  }
`

const StyledTextColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    height: 450px;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
    width: 100%;
    gap: 24px;
    min-height: 160px;
    margin-bottom: -250px;
  }
`

const StyledImageWrap = styled.div<{ $active: boolean }>`
  width: 100%;
  position: absolute;
  top: 0;
  transition: opacity 1s ease;
  opacity: ${(p) => (p.$active ? 1 : 0)};

  img {
    max-width: 630px;
    height: auto;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
    height: 400px;
  }
`

const StyledImageOverlay = styled.div`
  position: relative;
  border-radius: 24px;
  overflow: hidden;
  height: auto;

  &::after {
    content: '';
    background: rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
  }
`

const StyledImageText = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
  color: white;

  h3 {
    margin: 0;
    font-size: ${(p) => p.theme.v2.typography.display.xs.fontSize};
    line-height: ${(p) => p.theme.v2.typography.display.xs.lineHeight};
    font-family: ${(p) => p.theme.v2.font.nantes};
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 55%;
      width: 0%;
      height: 2px;
      background-color: white;
      transition: width 0.8s ease-in;
    }

    &.active {
      &::after {
        width: 100%;
      }
    }
  }

  h4 {
    margin: 0;
    font-family: ${(p) => p.theme.v2.font.nantes};
    font-size: ${(p) => p.theme.v2.typography.display.xxl.fontSize};
    line-height: ${(p) => p.theme.v2.typography.display.xxl.lineHeight};

    em {
      font-family: ${(p) => p.theme.v2.font.nantesItalic};
    }
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    h3 {
      font-size: ${(p) => p.theme.v2.typography.display.xxs.fontSize};
      line-height: ${(p) => p.theme.v2.typography.display.xxs.lineHeight};
    }

    h4 {
      font-size: ${(p) => p.theme.v2.typography.display.s.fontSize};
      line-height: ${(p) => p.theme.v2.typography.display.s.lineHeight};
    }
  }
`

const StyledTextDiv = styled.div`
  opacity: 0.2;
  display: flex;
  gap: 48px;
  flex: 1;

  &.active {
    opacity: 1;
  }
`

const StyledTextInner = styled.div`
  border-top: 1px solid rgba(45, 50, 70, 0.16);
  padding: 16px;
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 0;
  }

  h3 {
    font-size: ${(p) => p.theme.v2.typography.display.s.fontSize};
    line-height: ${(p) => p.theme.v2.typography.display.l.lineHeight};
    font-family: ${(p) => p.theme.v2.font.nantes};
    letter-spacing: -1.3px;
    margin: 0;

    u,
    em {
      text-decoration: none;
      position: relative;
      font-family: ${(p) => p.theme.v2.font.nantesItalic};

      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 4px;
        bottom: -10px;
        left: 0;
        background-color: ${(p) => p.theme.v2.text.yellow};
        z-index: -1;
        transition: width 0.4s ease-in;

        @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
          bottom: -7px;
        }
      }
    }
  }

  &.active {
    h3 u:after,
    h3 em:after {
      width: 100%;
    }
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding-top: 32px;

    h3 {
      font-size: ${(p) => p.theme.v2.typography.body.xxl.fontSize};
      line-height: ${(p) => p.theme.v2.typography.display.xxs.lineHeight};
    }
  }
`

const StyledTextNumber = styled.div`
  border-radius: 0 24px 24px 0;
  border: 1px solid ${(p) => p.theme.v2.surface.dark};
  border-left: none;
  width: 14px;
  position: relative;

  span {
    opacity: 0;
    position: absolute;
    right: -8px;
    top: 50%;
    background-color: ${(p) => p.theme.v2.surface.dark};
    color: white;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    font-size: ${(p) => p.theme.v2.typography.label.m.fontSize};
  }

  &.active {
    span {
      opacity: 1;
    }
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
    display: none;
  }
`

const StyledPin = styled.div`
  margin: 0 auto;
`

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 48px;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-top: 32px;
  }
`

const trackButtonClick = (btnName: string) => {
  trackCtaClick('homepage', btnName)
}

export const TwoColumnSlides: React.FC<TwoColumnSlidesProps> = ({ record }) => {
  const { headline, slides } = record
  const { isMobile } = useWindowSize()
  const [activeImage, setActiveImage] = useState(0)
  const [isButtonActive, setIsButtonActive] = useState(false)
  const totalDuration = isMobile ? 400 : 1500
  const singleDuration = totalDuration / slides.length
  const headlineRefs = useRef<Array<React.RefObject<HTMLDivElement>>>(slides.map(() => createRef()))
  const containerRef = useRef<HTMLDivElement>(null)
  const parentRef = useRef<HTMLDivElement>(null)
  const textColumnRef = useRef<HTMLDivElement>(null)
  const buttonLabel = 'Get started'
  const buttonUrl = '/quiz'

  useEffect(() => {
    let mm = gsap.matchMedia()
    mm.add('(max-width: 800px)', () => {
      const animateText = gsap.to(textColumnRef.current, { y: -280, ease: 'none' })

      ScrollTrigger.create({
        trigger: containerRef.current,
        start: 'top 70px',
        end: '+=' + totalDuration,
        pin: true,
        scrub: true,
        animation: animateText,
      })
    })

    mm.add('(min-width: 801px)', () => {
      ScrollTrigger.create({
        trigger: containerRef.current,
        start: 'top 100px',
        end: '+=' + totalDuration,
        pin: true,
        scrub: true,
      })
    })

    //Fade in text on scroll and fade through images
    slides.forEach((_, i) => {
      const elem = headlineRefs.current[i]?.current
      if (!elem) return

      ScrollTrigger.create({
        trigger: parentRef.current,
        start: 'top -=' + singleDuration * i,
        end: '+=' + singleDuration,
        toggleActions: 'play reverse play reverse',
        onEnter: () => activeSlideHandler(i),
        onEnterBack: () => activeSlideHandler(i),
      })
    })

    const activeSlideHandler = (i: number): void => {
      setActiveImage(i)
      setIsButtonActive(i + 1 === slides.length)
    }
  }, [])

  return (
    <StyledTwoColumnSlides>
      <div ref={parentRef}>
        <StyledPin>
          <Container $size="wide">
            <StyledHeadline>
              <StructuredText data={headline} />
            </StyledHeadline>
            <StyledRow ref={containerRef}>
              <StyledImageColumn>
                {slides.map((image, i) => (
                  <StyledImageWrap key={image.id} $active={activeImage === i}>
                    <StyledImageOverlay>
                      <StyledImageText>
                        <h3 className={activeImage === i ? 'active' : ''}>{image.imageStrikethrough}</h3>
                        <h4>
                          <em>{image.imageText}</em>
                        </h4>
                      </StyledImageText>
                      <Image data={image.image.responsiveImage} />
                    </StyledImageOverlay>
                  </StyledImageWrap>
                ))}
              </StyledImageColumn>
              <StyledTextColumn ref={textColumnRef}>
                {slides.map((text, i) => {
                  return (
                    <StyledTextDiv
                      key={text.id}
                      data-slide-id={text.id}
                      ref={headlineRefs.current[i]}
                      data-index={i}
                      className={activeImage === i ? 'active' : ''}
                    >
                      <StyledTextNumber className={activeImage === i ? 'active' : ''}>
                        <span>{i + 1}</span>
                      </StyledTextNumber>
                      <StyledTextInner className={activeImage === i ? 'active' : ''}>
                        <StructuredText data={text.slideText} />
                      </StyledTextInner>
                    </StyledTextDiv>
                  )
                })}
                {isMobile && (
                  <StyledTextDiv className="active">
                    <StyledTextInner className="active">
                      <Button
                        url={buttonUrl}
                        text={buttonLabel}
                        style="primary"
                        withArrow
                        size="fullwidth-less-padding"
                        animateArrow={isButtonActive}
                        onClick={() => trackButtonClick(`${buttonLabel}-begin-to-change-section`)}
                      />
                    </StyledTextInner>
                  </StyledTextDiv>
                )}
              </StyledTextColumn>
            </StyledRow>
            {!isMobile && (
              <StyledButtonWrapper>
                <Button
                  url={buttonUrl}
                  text={buttonLabel}
                  style="primary"
                  withArrow
                  onClick={() => trackButtonClick(`${buttonLabel}-begin-to-change-section`)}
                />
              </StyledButtonWrapper>
            )}
          </Container>
        </StyledPin>
      </div>
    </StyledTwoColumnSlides>
  )
}
