import Image from 'next/image'
import resultsGraphsRyeBread from '@/components/shared/lottie/resultsGraphsRyeBread.json'
import resultsGraphsWhiteBread from '@/components/shared/lottie/resultsGraphsWhiteBread.json'
import { LottieAnimation } from '@/components/shared/LottieAnimation'
import { CSSProperties, useEffect, useState } from 'react'
import styled from 'styled-components'
import { theme } from 'styles/theme'

const StyledContainer = styled.div`
  height: 720px;

  @media screen and (max-width: ${theme.breakpoints.tablet}) {
    height: 270px;
  }
`

const StyledImageContent = styled.div`
  .animation {
    left: 0;
    right: 0;
    position: absolute;
    top: 58%;
    z-index: 1;
    opacity: 0;
  }

  .image {
    left: 0;
    mix-blend-mode: darken;
    opacity: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 15%;
    transition: opacity 750ms ease;

    img {
      display: inline-block;
    }
  }

  .active {
    opacity: 1;
  }

  @media screen and (max-width: ${theme.breakpoints.tablet}) {
    position: relative;

    .animation {
      top: 190px;
    }
  }
`

const animationStyle = (isMobileDevice: boolean) => {
  return {
    ...(isMobileDevice ? { height: '106px' } : { height: '140px' }),
  } as CSSProperties
}

const SLIDES = [
  {
    slideNo: 0,
    alt: 'White bread with toppings',
    imageName: 'white-bread.png',
    animationName: resultsGraphsWhiteBread,
  },
  {
    slideNo: 1,
    alt: 'Rye bread with toppings',
    imageName: 'rye-bread.png',
    animationName: resultsGraphsRyeBread,
  },
]

const SLIDE_PRESENTATION_TIME_MS = 8500

export const HomePersonalisedAdviceAnimation = () => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [isMobileDevice, setIsMobileDevice] = useState(false)

  useEffect(() => {
    const isMobileDevice = window.innerWidth <= Number(theme.breakpoints.tablet.slice(0, -2))
    setIsMobileDevice(isMobileDevice)
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((currentSlide + 1) % SLIDES.length)
    }, SLIDE_PRESENTATION_TIME_MS)

    return () => {
      clearInterval(intervalId)
    }
  })

  return (
    <StyledContainer>
      {SLIDES.map((slide, index) => (
        <StyledImageContent key={slide.imageName}>
          <div className={`animation ${index === currentSlide ? 'active' : null}`}>
            <LottieAnimation
              ariaLabel="ZOE score for bread and impact on blood sugar, blood fat and gut health"
              role="figure"
              source={slide.animationName}
              style={animationStyle(isMobileDevice)}
            />
          </div>
          <div className={`image ${index === currentSlide ? 'active' : null}`}>
            <Image
              alt={slide.alt}
              height={isMobileDevice ? 283 : 416}
              layout="fixed"
              src={`/assets/img/homepage/${slide.imageName}`}
              width={isMobileDevice ? 352 : 518}
            />
          </div>
        </StyledImageContent>
      ))}
    </StyledContainer>
  )
}
