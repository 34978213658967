const UTM_COOKIE_NAME_TO_QUERY_PARAM = {
  zoe_cam: 'zoe_cam',
  zoe_con: 'zoe_con',
  zoe_med: 'zoe_med',
  zoe_sou: 'zoe_sou',
  zoe_adg: 'zoe_adg',
  zoe_term: 'zoe_term',
  zoe_fb_id: 'zoe_fb_id',
  zoe_chan_init: 'zoe_chan',
  zoe_loc_init: 'zoe_loc',
}

export const ZOE_UTM_COOKIES_WHITELIST = Object.keys(UTM_COOKIE_NAME_TO_QUERY_PARAM)

export const buildZoeDataQueryStringMap = (zoeUtmCookies: object) => {
  return Object.entries(zoeUtmCookies)
    .filter(([key]) => ZOE_UTM_COOKIES_WHITELIST.includes(key))
    .reduce((zoeUtmMap, [key, value]) => ({ ...zoeUtmMap, [UTM_COOKIE_NAME_TO_QUERY_PARAM[key]]: value }), {})
}
