import { MediaAndContentLayout } from '@/components/pages/gutshot/MediaAndContentLayout'
import { ListWithIcons } from '@/components/pages/self-serve/blocks/ListWithIcon'
import Image from 'next/image'
import { trackCtaClick } from '@/lib/gtm/gtmV2'
import { useWindowSize } from '@/lib/hooks/useWindowSize'
import { StructuredText } from 'react-datocms'
import styled from 'styled-components'
import { HomepageHealthBenefitRecord } from 'types'
import { Button } from '../shared/Button'
import { HealthBenefitsListWithIcons } from './HealthBenefitsListWithIcons'

const StyledHealthBenefits = styled.div`
  margin-bottom: 80px;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
    margin-bottom: 60px;
    border-radius: 32px;
    padding-bottom: 32px;
  }
`
const StyledListTitle = styled.div`
  color: ${(props) => props.theme.v2.onSurface.text};
  max-width: 350px;

  h2 {
    font-size: 46px;
    line-height: 64px;
    margin-top: 0;
    font-family: ${(p) => p.theme.v2.font.nantes};

    em {
      font-family: ${(p) => p.theme.v2.font.nantesItalic};
    }
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-top: 8px;

    h2 {
      font-size: ${(p) => p.theme.v2.typography.body.l.fontSize};
      line-height: ${(p) => p.theme.v2.typography.body.l.lineHeight};
    }
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
    font-size: 34px;
    line-height: 48px;
    margin-top: 16px;

    h2 {
      font-size: 34px;
      line-height: 48px;
    }
  }
`

const StyledCta = styled.div`
  display: flex;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
    justify-content: center;
  }
`

type HealthBenefitsProps = {
  record: HomepageHealthBenefitRecord
  hideMediaOnMobile?: boolean
}

const trackButtonClick = (btnName: string) => {
  trackCtaClick('homepage', btnName)
}

export const HealthBenefits: React.FC<HealthBenefitsProps> = ({ record, hideMediaOnMobile }) => {
  const { isMobile } = useWindowSize()
  const { title, list, image, cta } = record
  const benefits = list.split(',')

  return (
    <StyledHealthBenefits>
      <MediaAndContentLayout
        content={
          <div>
            <StyledListTitle>
              <StructuredText data={title} />
            </StyledListTitle>
            <ListWithIcons>
              {benefits.map((benefit) => (
                <HealthBenefitsListWithIcons key={benefit} text={benefit} />
              ))}
            </ListWithIcons>
            <StyledCta>
              {cta[0].buttons.map((button) => (
                <Button
                  key={button.label}
                  url={button.link}
                  text={button.label}
                  style={button.buttonType}
                  onClick={() => trackButtonClick(`${button.label}-health-benefit-section`)}
                />
              ))}
            </StyledCta>
          </div>
        }
        orientation={'right'}
        media={
          !(hideMediaOnMobile && isMobile) && (
            <Image
              src={image.responsiveImage.src || ''}
              width={670}
              height={670}
              alt={image.responsiveImage.alt || ''}
            />
          )
        }
      />
    </StyledHealthBenefits>
  )
}
