import { DatoImage } from '@/elements/global/DatoImage'
import { ReactNode } from 'react'
import styled, { CSSObject, css } from 'styled-components'
import { ImageFullContainerRecord } from 'types'

const StyledImageFullContainer = styled.div<{
  $containerStyle?: CSSObject
  $mobileOrder?: number
  $rounded?: boolean
}>`
  position: relative;
  ${(props) => props.$containerStyle && { ...props.$containerStyle }};

  .text-overlay {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  ${(p) =>
    p.$rounded &&
    css`
      border-radius: 30px;
      overflow: hidden;
    `}

  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    ${(props) => props.$mobileOrder && `order: ${props.$mobileOrder}`};
  }
`

export const ImageFullContainer = ({
  record,
  containerStyle,
  mobileOrder,
  customImage,
  customTextOverlay,
  rounded,
}: {
  record: ImageFullContainerRecord
  containerStyle?: CSSObject
  mobileOrder?: number
  customImage?: ReactNode
  customTextOverlay?: ReactNode
  rounded?: boolean
}) => {
  return (
    <StyledImageFullContainer $containerStyle={containerStyle} $mobileOrder={mobileOrder} $rounded={rounded}>
      {customImage ? customImage : <DatoImage data={record.image?.responsiveImage} layout="responsive" />}
      <div className="text-overlay">{customTextOverlay}</div>
    </StyledImageFullContainer>
  )
}
