import styled from 'styled-components'
import { CheckMark } from '@/components/shared/icons/CheckMark'

const StyledCheckMark = styled.div`
  margin-right: 12px;
  padding-top: 4px;
`

const StyledListItemWithIcons = styled.li`
  display: flex;
  align-items: center;
  padding-bottom: 12px;
  margin-top: 12px;
  font-size: ${(p) => p.theme.v2.typography.body.s.fontSize};
  line-height: ${(p) => p.theme.v2.typography.body.s.lineHeight};
  color: ${(p) => p.theme.v2.surface.dark};

  &:last-of-type {
    margin-bottom: 0;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    align-items: center;
  }
`

type HealthBenefitsListWithIconsProps = {
  text: string
}

export const HealthBenefitsListWithIcons: React.FC<HealthBenefitsListWithIconsProps> = ({ text }) => {
  return (
    <StyledListItemWithIcons>
      <StyledCheckMark>
        <CheckMark colour="#FFD100" />
      </StyledCheckMark>
      <span>{text}</span>
    </StyledListItemWithIcons>
  )
}
