import { CentredContentBlock } from '@/components/blocks/CentredContentBlock'
import { HealthBenefits } from '@/components/blocks/HealthBenefits'
import { HomePageArticlesLink } from '@/components/blocks/HomePageArticlesLink'
import { ImageAndText } from '@/components/blocks/ImageAndText'
import { LogoDropdown } from '@/components/blocks/LogoDropdown/LogoDropdown'
import { OffsetGrid } from '@/components/blocks/OffsetGrid'
import { PageIntro } from '@/components/blocks/PageIntro'
import { ScienceSection } from '@/components/blocks/ScienceSection'
import { TwoColumnSlides } from '@/components/blocks/TwoColumnSlides'
import { HomeAnimatedTextOverlay } from '@/components/pages/home/HomeAnimatedTextOverlay'
import { HomePersonalisedAdviceAnimation } from '@/components/pages/home/HomePersonalisedAdviceAnimation'
import { PodcastSection } from '@/components/pages/home/PodcastSection'
import { TrustpilotSection } from '@/components/pages/home/TrustpilotSection'
import { CHECKOUT_GB } from '@/lib/checkout/types'
import { useCheckoutCountry } from '@/lib/hooks/useCheckoutCountry'
import { useEffect, useState } from 'react'
import { HomePageContentRecord } from 'types'

const CUSTOM_IMAGE_COMPONENTS = {
  HomePersonalisedAdviceAnimation: <HomePersonalisedAdviceAnimation />,
}

const getCustomImageComponent = (record) => {
  if (record.leftSection[0].customImage && record.leftSection[0].customImageComponentName) {
    return CUSTOM_IMAGE_COMPONENTS[record.leftSection[0].customImageComponentName]
  }

  if (record.rightSection[0].customImage && record.rightSection[0].customImageComponentName) {
    return CUSTOM_IMAGE_COMPONENTS[record.rightSection[0].customImageComponentName]
  }

  return undefined
}

const renderBlock = (record: HomePageContentRecord) => {
  switch (record.__typename) {
    case 'ImageAndTextBlockRecord':
      return (
        <ImageAndText
          key={record.id}
          record={record}
          containerStyle={{
            height: 'auto',
            width: '100%',
            ...(record.backgroundColour?.hex && { backgroundColor: record.backgroundColour.hex }),
            ...(record.style === 'narrow' && { maxWidth: '1200px', margin: '0 auto' }),
          }}
          imageSubContainerStyle={{ height: 'auto', width: '100%' }}
          textSubContainerStyle={{
            height: 'auto',
            width: '400px',
          }}
          customImage={getCustomImageComponent(record)}
          customTextOverlay={
            record.leftSection[0].customTextOverlay || record.rightSection[0].customTextOverlay ? (
              <HomeAnimatedTextOverlay />
            ) : null
          }
        />
      )
    case 'CentredContentBlockRecord':
      return <CentredContentBlock record={record} key={record.id} />
    case 'LogoDropdownRecord':
      return <LogoDropdown record={record} key={record.id} />
    case 'TrustpilotRecord':
      return <TrustpilotSection trustpilot={record} key={record.id} ctaSection="homepage" />
    case 'ZoePodcastRecord':
      return <PodcastSection record={record} key={record.id} />
    case 'HomePageArticlesLinkRecord':
      return <HomePageArticlesLink record={record} key={record.id} />
    case 'PageIntroRecord':
      return <PageIntro record={record} key={record.id} />
    case 'TwoColumnSlideRecord':
      return <TwoColumnSlides record={record} key={record.id} />
    case 'OffsetGridRecord':
      return <OffsetGrid record={record} key={record.id} />
    case 'ScienceRecord':
      return <ScienceSection key={record.id} />
    case 'HomepageHealthBenefitRecord':
      return <HealthBenefits record={record} key={record.id} hideMediaOnMobile />
    default:
      return null
  }
}

export const HomeContent = ({ content }) => {
  const { isReady, checkoutCountry } = useCheckoutCountry()
  const [validLocales, setValidLocales] = useState<string[]>(['all', 'All', CHECKOUT_GB])
  useEffect(() => {
    if (isReady && checkoutCountry) {
      setValidLocales(['all', checkoutCountry])
    }
  }, [isReady, checkoutCountry])

  content = content.filter((record: HomePageContentRecord) => {
    if ('localisation' in record) {
      const localisation = record.localisation[0]
      return validLocales.includes(localisation.country)
    }
    return true
  })

  return <>{(content || []).map(renderBlock)}</>
}
