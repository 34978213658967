import { useEffect, useState } from 'react'
import styled from 'styled-components'

const StyledText = styled.div`
  p {
    color: ${(props) => props.theme.v2.surface.white};
    font-family: ${(props) => props.theme.v2.font.nantes};
    font-size: ${(props) => props.theme.v2.typography.display.xl.fontSize};
    line-height: ${(props) => props.theme.v2.typography.display.xl.lineHeight};
    margin: 0;
    text-align: center;
  }

  .fade-in {
    transition: opacity 1.5s ease;
  }

  .fade-out {
    opacity: 0;
    transition: opacity 1.5s ease;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.v2.typography.display.s.fontSize};
    line-height: ${(props) => props.theme.v2.typography.display.s.lineHeight};
  }
`

const FADE_INTERVAL_MS = 1750
const WORD_CHANGE_INTERVAL_MS = FADE_INTERVAL_MS * 2
const WORDS_TO_ANIMATE = ['energy', 'health', 'dreams', 'future']

type FadeProp = { fade: 'fade-in' | 'fade-out' }

export const HomeAnimatedTextOverlay = () => {
  const [fadeProp, setFadeProp] = useState<FadeProp>({ fade: 'fade-in' })
  const [wordOrder, setWordOrder] = useState(0)

  useEffect(() => {
    const fadeTimeout = setInterval(() => {
      fadeProp.fade === 'fade-in' ? setFadeProp({ fade: 'fade-out' }) : setFadeProp({ fade: 'fade-in' })
    }, FADE_INTERVAL_MS)

    return () => clearInterval(fadeTimeout)
  }, [fadeProp])

  useEffect(() => {
    const wordOrderTimeout = setInterval(() => {
      setWordOrder((prevWordOrder) => (prevWordOrder + 1) % WORDS_TO_ANIMATE.length)
    }, WORD_CHANGE_INTERVAL_MS)

    return () => clearInterval(wordOrderTimeout)
  }, [])

  return (
    <StyledText>
      <p>Feed your</p>
      <p className={fadeProp.fade}>{WORDS_TO_ANIMATE[wordOrder]}</p>
    </StyledText>
  )
}
